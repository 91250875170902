import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';

const NoPurchaseOrdersError = ({ onClick }) => (
  <Typography variant="h5" paragraph>
    Actualmente no tenemos órdenes de compra disponibles.
    Por favor, agrega una {' '}
    <Link component="span" variant="text" onClick={onClick}>
      aquí
    </Link>
    {' '}para comenzar a evaluar.
  </Typography>
);

NoPurchaseOrdersError.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default NoPurchaseOrdersError;

import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { useMutation } from '@apollo/client';
import { ADD_COMPANY_PREFERENCES, DELETE_COMPANY_PREFERENCES } from '@fingo/lib/graphql';

const mailTranslate = {
  suppliers_Merit: 'Notificar facturas con mérito ejecutivo',
  suppliers_Acknowledgment: 'Notificar facturas con acuse de recibo',
};

const EmailSwitch = ({ switches, setSwitches, company, relation }) => {
  const [setNewPreferences, { loading: loadingAddPref }] = useMutation(
    ADD_COMPANY_PREFERENCES,
  );
  const [deleteNewPreferences, { loading: loadingDeletePref }] = useMutation(
    DELETE_COMPANY_PREFERENCES,
  );
  const isSelected = (preference) => {
    if (switches[preference].find((item) => item.id === company)) {
      return true;
    }
    return false;
  };
  const handleSwitch = (preference) => {
    const queryVariable = {
      companyId: company,
      mail: preference,
    };
    if (isSelected(preference)) {
      deleteNewPreferences({ variables: queryVariable });
      setSwitches((oldSwitches) => ({
        ...oldSwitches,
        [preference]: oldSwitches[preference].filter((item) => item.id !== company),
      }));
      return;
    }
    setNewPreferences({ variables: queryVariable });
    setSwitches((oldSwitches) => ({
      ...oldSwitches,
      [preference]: [...oldSwitches[preference], { id: company }],
    }));
  };
  return (
    <FormGroup row sx={{ position: 'absolute', right: 0 }}>
      {Object.keys(switches).map((preference) => (
        preference.includes(relation)
        && (
          <FormControlLabel
            key={preference}
            control={(
              <Switch
                position="top"
                checked={isSelected(preference)}
                onChange={() => handleSwitch(preference)}
                disabled={loadingAddPref || loadingDeletePref}
              />
            )}
            labelPlacement="top"
            label={mailTranslate[preference]}
          />
        )

      ))}

    </FormGroup>
  );
};

EmailSwitch.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  switches: PropTypes.object.isRequired,
  setSwitches: PropTypes.func.isRequired,
  company: PropTypes.string.isRequired,
  relation: PropTypes.string.isRequired,
};

export default EmailSwitch;
